import React, { useState } from 'react';
import { useEffect } from 'react';
import './Studio.css';
import { Row, Col } from 'react-bootstrap';
import { PRODUCTS } from '../const.js';
import { TeemillThumbnail } from './TeemillButton';
import { arrowCounterClockSVG, heartSVG } from '../SVGs';
import { Button, Carousel } from 'react-bootstrap';
import StudioInfo from '@oos/core/components/StudioInfo';
import { Link } from 'react-router-dom';

export default function Studio(props) {
    const designs = props.designs;

    const [selected, setSelected] = useState(0);

    const image = designs.length && designs[selected].image;

    console.log(designs);

    const [isTablet, setIsTablet] = useState(false);

    useEffect(() => {
        function checkTablet() {
            setIsTablet(window.innerWidth < 992);
        }
        checkTablet();
        window.addEventListener('resize', checkTablet);
        return () => window.removeEventListener('resize', checkTablet);
    }, []);
    
    return (
        <Row>
            <Col md={{span: 8, order: "first"}} xs={12}>
                <div style={{ height: "2vh" }} />
                <Link to="/favourites" style={{textDecoration: "none"}}>
                    <div style={{ fontSize: "calc(8px + 0.6vmin)"}} className="Home-title">2 images saved to {heartSVG} My Designs</div>
                </Link>
                <div style={{ height: "1vh" }} />
                <OutputCarousel designs={designs} selected={selected} onSelect={setSelected}/>
            </Col>
            <Col md={4} xs={{span: 12, order: 'first'}}>
                <Button
                    variant=""
                    className='cursorPointer buttonStyle-gold mx-2 my-2'
                    onClick={props.onReset}>
                    <div
                        className="cursorPointer Home-title"
                        style={{width: "auto", maxHeight: "8vw", fontSize: "calc(10px + 1vmin)", color: "black"}}
                        >{arrowCounterClockSVG}
                        <br />
                        Create New
                    </div>
                </Button>
                { !isTablet &&
                <Link to="/favourites">
                    <Button
                        variant=""
                        className='cursorPointer buttonStyle-gold mx-2 my-2'
                        onClick={props.onReset}>
                        <div
                            className="cursorPointer Home-title"
                            style={{width: "auto", maxHeight: "8vw", fontSize: "calc(10px + 1vmin)", color: "black"}}
                            >{heartSVG}
                            <br />
                            My Designs
                        </div>
                    </Button>
                </Link>
                }
                <TeemillThumbnail image={image} product={PRODUCTS.kids_basic} />
            </Col>
        </Row>
    )
};

function OutputCarousel(props) {
    const designs = props.designs;
    const nextImage = () => props.onSelect((props.selected+1) % designs.length);

    return (
        <>
        <Carousel interval={null} activeIndex={props.selected} onSelect={props.onSelect} variant="dark">
            {designs.map((d) =>
                <Carousel.Item key={d.id}>
                    <img
                    id="design"
                    className="hover-zoom-light GenerateStyle-styles"
                    src={d.image}
                    onClick={nextImage}
                    alt="generative art"
                    />
                    <Carousel.Caption>
                        {/* <div className='Home-title'>{d.id}</div> */}
                    </Carousel.Caption>
                </Carousel.Item>
            )}
        </Carousel>
        </>
    );
}