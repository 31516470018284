import React from "react";
import Modal from 'react-bootstrap/Modal';
import "./ReturnsModal.css";

export default function ReturnsModal(props) {
    return(
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title className="ReturnModal-h1">Returns and Exchanges</Modal.Title>
            </Modal.Header>
            <Modal.Body className="ReturnsModal-text">
                <p>Short answer: Unfortunately, we don't offer out of the box returns or exchanges for our custom products.</p>
                <h2 className="ReturnModal-h2">But...</h2>
                <p>We want you to be happy with your creations! So if in doubt, please reach out to us so we can find a solution for you.</p>
                <h2 className="ReturnModal-h2">How can I return or exchange a damaged item?</h2>
                <p>If your product is damaged or not according to our high quality standard, you will of course be able to exchange or return your item.
                <div style={{height: "1vh"}} />
                <h2 className="ReturnModal-h2">The quickest way to return your item is online at <a href="https://shop.bumbletiger.com/help/">shop.bumbletiger.com/help</a> and select "Returns" from the menu.</h2> We will process your request as quickly as possible.</p>
                <h2 className="ReturnModal-h2">We are part of the OilOnShirt family. For any questions, please email <a href="mailto:hello@oilonshirt.com">hello@oilonshirt.com</a></h2>
            </Modal.Body>
            <Modal.Footer className="me-auto">
                <p>We will respond within 24 hours.</p>
            </Modal.Footer>
        </Modal>
    )
}