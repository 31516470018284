import { Row, Col, Button } from "react-bootstrap";

export default function ProductGallery (props) {
    return (
        <>
            <h1>Shop Pre-Designed</h1>
            <Row className="Home-gallery Home-gallery-products">
            {props.gallery.map((img) => (
            <Col md={4} xs={6} key={img.id} className="my-2">
                <Button size="lg" variant='outline-light' className="Home-tabs Home-title" href="https://shop.bumbletiger.com/collection/kids/" target="_blank" rel="noreferrer">
                    <img
                        className="d-block w-100 Collection-tabs-img hover-zoom-light"
                        src={img.image}
                        alt="Custom Ai Print Clothes"
                    />
                    <div className='Home-text'>
                        <p>{img.desc}</p>
                    </div>
                </Button>
            </Col>
            ))}
            </Row>
        </>
    )
}