import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './GenerateStyle.css';
import { Button, Carousel } from 'react-bootstrap';
import { stackSVG, closeRoundSVG, checkSVG, arrowCounterClockSVG } from '../SVGs';
import { Row, Col } from 'react-bootstrap';
import ProgressBar from '@oos/core/components/ProgressBar';
import { shuffleArray } from '@oos/core/shuffle';
import ImageRadios from '@oos/core/components/ImageRadios';
import StyleRadios from './StyleRadios.module.css'
import { useDesigns } from '@oos/core/components/DesignsContext';
import PaintingLoader from './PaintingLoader';
import Studio from './Studio';
import StudioInfo from '@oos/core/components/StudioInfo';


export const STYLES = [
    // {image: "style_minimalism.webp", id: "minimalism", title: "Minimalism"},
    {image: "style_california.webp", id: "california", title: "California"},
    {image: "style_triangle.webp", id: "triangle", title: "Triangle"},
    {image: "style_polygon.webp", id: "poly", title: "Polygon"},
    // {image: "style_cartoon.webp", id: "cartoon", title: "Cartoon"},
    // {image: "style_waves.webp", id: "wave", title: "Wave"},
    {image: "style_space.webp", id: "space", title: "Space"},
    {image: "style_playful.webp", id: "playful", title: "Playful"},
    {image: "style_pixel.webp", id: "pixel", title: "Pixel"},
    // {image: "style_lineart.webp", id: "lineart", title: "Lineart"},
    // {image: "style_none.webp", id: "none", title: "None"},
];

export const PROMPTS = [
    {desc: "Dancing Elephant", id: "1"},
    {desc: "Cute space cat flying in the galaxy", id: "2"},
    {desc: "Close up lion face portrait", id: "3"},
    {desc: "Fluffy robot relaxing on a beach", id: "4"},
    {desc: "Roaring Bear Portrait", id: "5"},
    {desc: "Portrait of a cute pug", id: "6"},
    {desc: "A shark dinosaur fusion", id: "7"},
    {desc: "Capybara playing the violin", id: "8"},
    {desc: "Cute fusion of a bumblebee and a tiger", id: "9"},
    {desc: "Fusion of a cute cat and a butterfly", id: "10"},
    {desc: "A cheerful rainbow made of ice cream scoops", id: "11"},
    {desc: "A group of smiling animals having a tea party", id: "12"},
    {desc: "Whimsical castles floating in a starry sky", id: "13"},
    {desc: "Friendly monsters playing hide-and-seek in a forest", id: "14"},
    {desc: "Adorable kittens riding on the backs of friendly dinosaurs", id: "15"},
    {desc: "Magical underwater world with mermaids and talking fish", id: "16"},
    {desc: "Playful robots playing soccer in a futuristic city", id: "17"},
    {desc: "Vibrant balloons carrying cute animals across the sky", id: "18"},
    {desc: "Smiling suns, moon, and stars having a celestial picnic", id: "19"},
    {desc: "Jungle filled with silly monkeys swinging from colorful vines", id: "20"},
];

export default function GenerateStyle(props) {
    const {dispatch} = useDesigns();
    const [description, setDescription] = useState("");
    const [style, setStyle] = useState(STYLES[0]);
    const [count, setCount] = useState(0);
    const [prompts, setPrompts] = useState();
    const max_length = props.max_length || 90;

    useEffect(() => {
        setPrompts({
            prompts: shuffleArray(PROMPTS).slice(0, 3),
        })
    }, []);

    const [designs, setDesigns] = useState({
        available: [],
    });

    const resetDesigns = (loading, errorMsg) => setDesigns({ available: [], loading, errorMsg });

    const [blur, setBlur] = useState(false);
    const toggleBlur = () => {
        setBlur(!blur);
        console.log('toggleBlur');
    };

    const handleSelectStyle = (id) => {
        setStyle(STYLES.find(e => e.id === id));
        window.dataLayer.push({
            'event': 'SelectStyle',
        });
    }

    const handleDesignError = (res) => {
        switch (res.status) {
            case 429:
                return "Sorry, you've reached your daily limit of 5 free stories."
            case 503:
                return "Sorry, our total daily budget for free stories has run out..."
        }
        return DEFAULT_DESIGN_ERROR_MSG
    };

    const handleCreate = () => {
        toggleBlur();
        if (!description || description.length < 1) {
            setDesigns({ ...designs, errorMsg: "Please describe your print" });
            return
        }
        window.dataLayer.push({
            'event': 'DesignNow',
        });
        resetDesigns(true);
        return axios({
            method: "post",
            withCredentials: true,
            url: `${process.env.REACT_APP_BACKEND_URL}/designs`,
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify({ content: description, style: style.id }),
        })
            .then((res) => {
                console.log(res);
                dispatch({ type: 'ADD_NEW_DESIGNS', payload: res.data });
                setDesigns({available: res.data});
            })
            .catch((err) => {
                console.log(err);
                const errorMsg = err.response ? handleDesignError(err.response) : DEFAULT_DESIGN_ERROR_MSG;
                resetDesigns(false, errorMsg);
            })
    };

    const handleReset = () => {
        resetDesigns();
        setBlur(false);
    }

    const handleDescription = (d) => {
        setDescription(d);
        setCount(d.length);
    }

    const ResetPrompt = () => {
        setDescription("");
        setCount(0);
    }

    return (
        <div>
            {!designs.available.length && <Row>
                <div className="Stylize-tabs GenerateStyle-caption">
                    { !description ?
                    <h3 className="GenerateStyle-caption Home-title">1. Select a Style</h3> : <div style={{height: "5vh"}}>{checkSVG}</div> }
                    <ImageRadios inputs={STYLES} value={style.id} 
                                 onChange={handleSelectStyle} styles={StyleRadios}/>
                </div>
            </Row>}
            <div style={{height:"2vh"}} />
            <Row>
                {!designs.available.length && <>
                    <Col xs={12}>

                        {!designs.loading && <>
                            {description && <><Button
                                variant=""
                                className="cursorPointer p-2 my-2 mx-2 buttonStyle-gold buttonAnimate"
                                onClick={handleCreate}>
                                Generate Now
                            </Button>
                            <Button
                                className="cursorPointer p-2 my-2 mx-2 buttonStyle-black buttonAnimate-sm"
                                variant=""
                                onClick={ResetPrompt}>
                                {closeRoundSVG}
                            </Button>
                            </>
                            }
                            <div style={{ height: "1vh" }} />
                            {prompts && !designs.errorMsg && <>
                                {!description && <>
                                    <h3 className="GenerateStyle-caption Home-title">2. Click on a story or enter your own text</h3>
                                    <AddPrompt text={prompts.prompts} onClick={handleDescription} />
                                </>}
                                <div style={{ height: "1vh" }} />
                            </>}
                        </>}

                        <div style={{height:"3vh"}} />

                        <label htmlFor="printDesignText">
                            <textarea
                                className='GenerateStyle-input GenerateStyle-caption'
                                id="printDesignText"
                                name="description"
                                value={description}
                                rows={4}
                                maxLength={max_length}
                                onChange={(e) => handleDescription(e.target.value)}
                                placeholder="Start typing..."
                                autoFocus
                            />
                            <div className="Home-title" style={{textAlign: 'end'}}>{count}/{max_length}</div>
                        </label>
                    </Col>
                    <div className={blur ? "GenerateStyle-overlay" : null }>
                    <div style={{height:"2vh"}} />
                        <div className='GenerateStyle-caption' style={{marginTop: "25vh", fontSize: "3vh"}}>
                            { designs.errorMsg ? <>
                                <div>{designs.errorMsg}</div>
                                <Button
                                variant=""
                                className="cursorPointer p-1 mx-2 buttonStyle-white GenerateStyle-caption"
                                onClick={handleReset}
                                style={{width: "10vw", maxHeight: "20vw"}}
                                >{closeRoundSVG} Close
                                </Button>
                            </> : <>
                                {designs.loading && <>
                                    <PaintingLoader />
                                    <div className='Home-title' style={{ marginTop: "2vh" }}>Generate up to 5 FREE Stories per day {checkSVG}</div>
                                    <ProgressBar duration={16} tick={1000} />
                                </>}
                            </>}
                        </div>
                    </div>
                </>}
                {designs.available.length ? <Studio designs={designs.available} onReset={handleReset} /> : null}
            </Row>
        </div>
    )
}

const DEFAULT_DESIGN_ERROR_MSG = "Sorry, something went wrong... please try again";

function AddPrompt(props) {
    return (
        <Row className="Home-gallery Home-gallery-products">
            {props.text.map((p) => (
            <Col xs={4} key={p.id} className="my-2">
                 <Button
                    className="cursorPointer p-2 GenerateStyle-prompts"
                    variant=''
                    onClick = {() => props.onClick(p.desc)}
                    >{p.desc}
                </Button>
            </Col>
            ))}
        </Row>
    )
}

