import React, { useEffect, useState } from 'react';
import './MaskedImage.css';
import { CANVAS } from '../const';

export default function MaskedImage(props) {
    const mask = props.mask;
    const canvas = props.canvas;
    const canvasRef = canvas.ref;
    const [cWidth, cHeight] = CANVAS[canvas.type];
    const [image, setImage] = useState(new Image());
    const [isLoading, setLoading] = useState(true);
    const [isDragging, setDragging] = useState(false);
    const [movePos, setMovePos] = useState({
        delta: [0, 0],
    });

    const imgAR = image.width / image.height;
    const relAR = imgAR / mask.aspectRatio;

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.globalCompositeOperation = 'source-over';
        context.clearRect(0, 0, cWidth, cHeight);
        mask.draw(context);

        context.globalCompositeOperation = 'source-in';
        if (relAR > 1) {
            const moveX = movePos.delta[0] * mask.width / window.innerWidth;
            const dW = mask.height * imgAR;
            const dH = mask.height;
            const dx = mask.dx + moveX - 0.5*(relAR - 1)*mask.width;
            const dy = mask.dy;

            // console.log(dx, dy, dW, dH);
            context.drawImage(image, dx, dy, dW, dH);
        } else {
            const dH = mask.width / imgAR;
            const dW = mask.width;
            const moveY = movePos.delta[1] * mask.height / window.innerHeight;
            const dx = mask.dx;
            const dy = mask.dy + moveY - 0.5*(1/relAR - 1)*mask.height;

            // console.log(dx, dy, dW, dH);
            context.drawImage(image, dx, dy, dW, dH);
        }
    }, [isLoading, movePos, mask]);

    useEffect(() => {
        const image = new Image();
        image.src = props.src;
        image.onload = () => setLoading(false);
        setImage(image);
        setLoading(true);
    }, [props.src]);

    const startDrag = (e) => {
        setMovePos({...movePos, prev: [e.pageX, e.pageY]});
        setDragging(true);
        // props.onChange();
    }

    const maxdx = (relAR - 1) * window.innerWidth / 2;
    const maxdy = (1/relAR - 1) * window.innerHeight / 2;

    const drag = (e) => {
        let dx = movePos.delta[0] + e.pageX - movePos.prev[0];
        let dy = movePos.delta[1] + e.pageY - movePos.prev[1];

        dx = Math.min(maxdx, Math.max(-maxdx, dx));
        dy = Math.min(maxdy, Math.max(-maxdy, dy));

        setMovePos({
            prev: [e.pageX, e.pageY],
            delta: movePos.prev ? [dx, dy] : movePos.delta
        })
    };

    const touchDrag = (e) => {
        if (e.touches.length > 1) {
            setDragging(false);
        } else {
            drag(e.targetTouches[0]);
        }
    };

    const handleTouchStart = (e) => {
        if (e.touches.length === 1) {
            startDrag(e.targetTouches[0]);
        }
    };

    useEffect(() => {
        if (isDragging) {
            window.addEventListener("mousemove", drag);
            window.addEventListener("touchmove", touchDrag);
            return () => {
                window.removeEventListener("mousemove", drag);
                window.removeEventListener("touchmove", touchDrag);
            };
        }
    }, [isDragging]);

    useEffect(() => {
        const stopDrag = () => setDragging(false);
        window.addEventListener("mouseup", stopDrag);
        window.addEventListener("touchend", stopDrag);
        window.addEventListener("touchcancel", stopDrag);
        return () => {
            window.removeEventListener("mouseup", stopDrag);
            window.removeEventListener("touchend", stopDrag);
            window.removeEventListener("touchcancel", stopDrag);
        };
    }, []);

    return <canvas ref={canvasRef} className={`Canvas Canvas-${canvas.type}`} width={cWidth} height={cHeight}
                   onMouseDown={e => startDrag(e)} onTouchStart={handleTouchStart}/>
}

