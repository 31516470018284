import './TShirt.css';
import React from 'react';

export default function TShirt(props) {
    const product = props.product;
    return (
        <div className="TShirt">
            <img className="TShirt-blank" src={product.image} />
            <div className="TShirt-design" style={{top: product.offset}}>{props.children}</div>
        </div>
    );
}