import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/esm/Container';
import Button from 'react-bootstrap/esm/Button';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import TeemillButton from '../design/TeemillButton';
import './ProductCard.css';
import { useDesigns } from '@oos/core/components/DesignsContext';
import { useNavigate } from "react-router-dom";
import { stackSVG } from '../SVGs';
// import { downloadImage } from '../download.js';

export default function DesignCard(props) {
    const { dispatch } = useDesigns();
    const navigate = useNavigate();
    
    const design = props.design;

    const [showAlert, setShowAlert] = React.useState(false);

    const handleRemove = () => axios({
        method: "delete",
        withCredentials: true,
        url: `${process.env.REACT_APP_BACKEND_URL}/designs/${design.id}`,
    })
    .then((res) => {
        console.log(res);
        dispatch({type: 'REMOVE_DESIGN', payload: res.data});
    })
        .catch((res) => console.log(res))
    
    const handleSelect = () => {
        dispatch({ type: 'SELECT_DESIGN', payload: design.id });
        navigate("/design");
    };

    return (
        <>
            <Container fluid className='my-2 ProductCard-tabs'>
                <Col className=''>
                    <img className='GenerateStyle-styles' id="design" src={design.image}></img>
                </Col>
                <Col>
                    <Card.Body>
                        <TeemillButton image={design.image}/>
                        <br />
                        {!showAlert && <Button onClick={() => setShowAlert(true)}variant="outline-danger" className='buttonStyle-red GenerateStyle-text my-2 p-3'>Delete</Button>}
                    </Card.Body>

                    <Alert show={showAlert} variant="danger" className='my-3 mx-2 ProductAlert'>
                        <Alert.Heading>Are you sure?</Alert.Heading>
                        {/* <p>
                        Deleting from your favourites will also delete it from your cart.
                        </p> */}
                        <hr />
                        <div className="d-flex justify-content-center">
                            <Button
                                onClick={handleRemove}
                                size="sm"
                                variant='outline-danger'
                                className='my-2 mx-1 buttonStyle-black'>
                                Yes, delete.
                            </Button>
                            <Button
                                onClick={() => setShowAlert(false)}
                                size="sm"
                                variant="outline-light"
                                className='my-2 mx-1 buttonStyle-black'>
                                Cancel
                            </Button>
                        </div>
                    </Alert>
                </Col>
            </Container>
        </>
    )
}