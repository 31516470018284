import React from "react";
import Modal from 'react-bootstrap/Modal';
import "./ContactModal.css";

export default function ContactModal(props) {
    return(
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title className="ContactModal-h1">Hiya</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2 className="ContactModal-h2">Questions?</h2>
                <p>We're happy to respond to any questions you might have.</p>
                <h2 className="ContactModal-h2">Just want to say hi?</h2>
                <p>Nothing more thrilling than getting to know our community!</p>
                <h2 className="ContactModal-h2">Want to complain?</h2>
                <p>Well then, we shall listen - carefully.</p>
                <br />
                <p>We are part of the OilOnShirt family, for any questions please email HELLO@OILONSHIRT.COM <span className="ContactModal-h2">hello@oilonshirt.com</span></p>
            </Modal.Body>
            <Modal.Footer className="me-auto">
                <p>We will respond within 24 hours.</p>
            </Modal.Footer>
        </Modal>
    )
}