import React, { useState, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import './Favourites.css';
import { useDesigns } from '@oos/core/components/DesignsContext';
import DesignCard from './DesignCard';
import Container from 'react-bootstrap/esm/Container';
import { Link } from 'react-router-dom';

export default function Favourites() {
    const { designs, dispatch } = useDesigns();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const num_available = designs.available.length;

    const observerTarget = useRef(null);

    const fetchData = () => {
        if (isLoading || error) { return }  // TODO stop fetching on error
        setIsLoading(true);
        setError(null);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/designs?page=${num_available}&limit=${DESIGNS_PAGE_LIMIT}`, { credentials: 'include' })
            .then(res => res.json())
            .then(designs => dispatch({ type: 'ADD_MORE_DESIGNS', payload: { designs, hasMore: designs.length >= DESIGNS_PAGE_LIMIT } }))
            .catch(_ => setError("Sorry, something went wrong... please reload the page"))
            .finally(() => setIsLoading(false))
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    // console.log(`fetching ${num_available}`);
                    fetchData();
                }
            },
            { threshold: 0 }
        );
        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }
        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [observerTarget, isLoading, num_available]);

    return (
        <Container fluid className="Favourites-width pageHeight">
            {
                num_available ? (
                    <Row>
                        {designs.available.map((d) =>
                            <Col lg={6} key={d.id}>
                                <DesignCard design={d} />
                            </Col>)}
                    </Row>
                ) : !designs.hasMore &&
                    <>
                        <p className='Favourites-text'>You have no designs yet.</p>
                        <Link to="/design">
                            <button
                                style={{ width: "22vw" }}
                                className='my-2 mx-2 px-2 buttonStyle-gold ImageUploadCloud Home-title'>
                                <span style={{ fontSize: "3vw", fontWeight: "100" }}>Start Creating</span>
                            </button>
                        </Link>
                    </>
            }
            {isLoading && <p className='Favourites-text'>Loading</p>}
            {error && <p>{error}</p>}
            {designs.hasMore && !error && <div ref={observerTarget}></div>}
        </Container>
    )
}

const DESIGNS_PAGE_LIMIT = 2;