import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { ScrollToTop } from '@oos/core/components/ScrollToTop';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './app/App';
import PageTitle from "@oos/core/components/PageTitle";
import Home from './home/Home';
import DesignPage from './design/Page';
import Favourites from './favourites/Favourites';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === "development") {
    // makeServer({ environment: "development" })
    const { worker } = require('./mocks/browser')
    worker.start({
        onUnhandledRequest: 'bypass',
    })
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<App />}>
					<Route index element={<Page page={<Home/>}/>}/>
					<Route path="design" element={<Page title="Design Now" page={<DesignPage/>}/>}/>
                    <Route path="favourites" element={<Page title="Favourites" page={<Favourites/>}/>}/>
					{/* <Route path="*" element={<Page title="Not Found" page={<NotFound />}/>}/> */}
				</Route>
			</Routes>
        </BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function Page(props) {
    const title = props.title || "Custom Organic Print Clothing for Kids";
    return (
        <PageTitle title={title}>
            {props.page}
        </PageTitle>
    );
}