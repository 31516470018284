export const PRODUCTS = {
    kids_basic: {
        image: "product_kids_basic_tshirt_final.webp",
        studio: "kids_tshirt_studio.webp",
        carousel: [
            {label: "Organic", image: "kids_male.jpeg"},
            {label: "Rocks", image: "kids_female.jpeg"},
        ],
        title: "Kids Basic T-Shirt",
        desc: "White organic cotton tshirt. Printed on demand in the UK. Unleash your creativity!",
        spec: "Circle Print: diameter 21cm. Certified Organic Cotton t-shirt, 155g/m2. Wash cool, hang dry. GM free. Not tested on animals. Does not contain animal-derived products. Printed in the UK with low waste printing tech. Made in a renewable energy powered factory audited for a wide range of social and sustainability criteria.",
        sizes: ["3-4y", "5-6y", "7-8y", "9-10y", "11-12y"],
        canvas: "kids",
        offset: "28.3%",
        price: 20,
        item_code: "RNC1",
        colours: "White,Black",
    },
    kids_jumper: {
        image: "product_kids_jumper_final.webp",
        studio: "kids_sweater_studio.webp",
        carousel: [
            {label: "Organic", image: "kids_boy_jumper.jpeg"},
            {label: "Rocks", image: "kids_girl_jumper.jpeg"},
        ],
        title: "Kids Jumper",
        desc: "Athletic Grey organic cotton jumper. Printed on demand in the UK.",
        spec: "Circle Print: diameter 21cm. Certified Organic Cotton Jumper, 300g/m2. Wash cool, hang dry. GM free. Not tested on animals. Does not contain animal-derived products. Printed in the UK with low waste printing tech. Made in a renewable energy powered factory audited for a wide range of social and sustainability criteria.",
        sizes: ["3-4y", "5-6y", "7-8y", "9-10y", "11-12y"],
        canvas: "kids",
        offset: "28.3%",
        price: 30,
        item_code: "RNC4",
        colours: "Athletic Grey",
    },
    art_portrait: {
        image: "large_portrait_artprint.png",
        studio: "art_print_portrait_studio.webp",
        carousel: [
            {label: "Custom Printed", image: "artprint_catalogue1.jpeg"},
            {label: "Unique Memories", image: "artprint_frame.jpeg"},
        ],
        title: "Portrait Art Print (29.7 x 42cm)",
        desc: "100% Recycled Paper. Printed on demand in the UK. Frame not included.",
        spec: "Large Portrait Art Print - 29.7 x 42 cm (11.6 x 16.5 in) (A3) printed on 300gsm 100% recycled paper. Rounded, white border margins, 0.5cm.",
        sizes: ["A3"],
        canvas: "art_portrait",
        offset: "10%",
        price: 20,
        item_code: "RNP5",
        colours: "White",
    },
    art_landscape: {
        image: "large_landscape_artprint.png",
        studio: "art_print_landscape_studio.webp",
        carousel: [
            {label: "Your Art", image: "art_print_landscape_plain.webp"},
            {label: "Epic Colours", image: "art_print_landscape.webp"},
        ],
        title: "Landscape Art Print (42 x 29.7cm)",
        desc: "100% Recycled Paper. Printed on demand in the UK. Frame not included.",
        spec: "Large Landscape Art Print - 42 x 29.7cm (16.5 x 11.6 in) (A3) printed on 300gsm 100% recycled paper. Rounded, white border margins, 0.5cm.",
        sizes: ["A3"],
        canvas: "art_landscape",
        offset: "10%",
        price: 20,
        item_code: "RNP6",
        colours: "White",
    },
    tote_bag: {
        image: "product_kids_basic_tshirt_final.webp",
        title: "Tote bag",
        desc: "Keep calm, and carry on.",
        price: 15,
        item_code: "RNT1",
        colours: "White,Black",
    },
}