import defaultStyles from './ImageRadios.module.css'

export default function ImageRadios(props) {
    const inputs = props.inputs;
    const styles = Object.assign({}, defaultStyles, props.styles);

    return (
        <div>
            {inputs.map((st) => (
                <div className={styles.layout} key={st.id}>
                    <label>
                        <input
                            className={styles.radioHidden}
                            type="radio"
                            name="styles"
                            value={st.id}
                            onChange={(e)=> props.onChange(e.target.value)}
                            checked={props.value === st.id}
                            readOnly
                        />
                        <img className={styles.image} src={st.image}/>
                    </label>
                    <div className={styles.text}>{st.title}</div>
                </div>
            ))}
        </div>
    );
}