import React from 'react';
import './Stylize.css';
import { Row } from 'react-bootstrap';
import GenerateStyle from './GenerateStyle';

export default function Stylize(props) {

    return (
        <div className="Stylize-tabs">
            <Row xs={12}>
                <GenerateStyle/>
            </Row>
        </div>
    )
};