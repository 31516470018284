import React from 'react';
import { bagLargeSVG } from '../SVGs.js';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { RingCircle } from '@oos/core/masks';
import './TeemillButton.css';
import TShirt from '@oos/core/components/TShirt'
import MaskedImage from '@oos/core/components/MaskedImage'
import { productsSVG } from '../SVGs';
import { useEffect, useState } from 'react';


const TEEMILL_API = 'https://teemill.com/omnis/v3/product/create';

const TSHIRT_CANVAS = [1575, 2100]
const TSHIRT_MASK = new RingCircle(0.5*TSHIRT_CANVAS[0], 0.36175*TSHIRT_CANVAS[1], 0.738*TSHIRT_CANVAS[0]);

export default function TeemillButton(props) {
    const canvasRef = React.useRef(null);
    const [cWidth, cHeight] = TSHIRT_CANVAS;
    const mask = TSHIRT_MASK;

    const maskImage = (image) => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.globalCompositeOperation = 'source-over';
        context.clearRect(0, 0, cWidth, cHeight);
        mask.draw(context);
        context.globalCompositeOperation = 'source-in';
        context.drawImage(image, mask.dx, mask.dy, mask.width, mask.width);  // assumes imgAR = 1 < maskAR
        return canvas.toDataURL();
    }

    const handleCreate = () => {
        window.dataLayer.push({
            'event': 'BrowseProductsFav',
        });
        var newTab = window.open('about:blank', '_blank');
        newTab.document.write(
            "<title>Custom Print Clothing</title>\
            <body style = 'background-color:#ffe6d4;width:100%;height:100%;margin:0;position:relative;' >\
                <img src='loader.gif' style='position:absolute;top:calc(50% - 256px);left:calc(50% - 256px);'/>\
            </body>"
        );

        const image = new Image();
        image.src = props.image;
        image.onload = () => {
            const base64_image = maskImage(image);
            axios({
                method: "post",
                url: TEEMILL_API,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${process.env.REACT_APP_TEEMILL_PK}`,
                },
                data: JSON.stringify({
                    image_url: base64_image,
                    ...PRODUCT_PREVIEW
                }),
            })
                .then(res => res.data.url) //getProductUrl(res.data.url))
                .then(url => newTab.location.href = url)
                .catch(err => console.error(err));
        };
    }

    return (
        <>
            <Button
                style={{width: "20vw", maxHeight: "15vw"}}
                className='mx-2 p-1 buttonStyle-gold ImageUploadCloud cursorPointer GenerateStyle-text'
                onClick={handleCreate}>
                {bagLargeSVG}
                <br />
                Browse Products
            </Button>
            <canvas ref={canvasRef} width={cWidth} height={cHeight} className="TeemillButton-Canvas"/>
        </>
    )
}

export function TeemillThumbnail(props) {
    const image = props.image;
    const product = props.product;
    const mask = TSHIRT_MASK;

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        function checkMobile() {
            setIsMobile(window.innerWidth < 768);
        }
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);


    const canvasRef = React.useRef(null);

    const handleCreate = () => {
        window.dataLayer.push({
            'event': 'BrowseProducts',
        });
        var newTab = window.open('about:blank', '_blank');
        newTab.document.write(
            "<title>Custom Print Clothing</title>\
            <body style = 'background-color:#ffe6d4;width:100%;height:100%;margin:0;position:relative;' >\
                <img src='loader.gif' style='position:absolute;top:calc(50% - 256px);left:calc(50% - 256px);'/>\
            </body>"
        );

        const canvas = canvasRef.current;
        const base64_image = canvas.toDataURL();
        axios({
            method: "post",
            url: TEEMILL_API,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.REACT_APP_TEEMILL_PK}`,
            },
            data: JSON.stringify({
                image_url: base64_image,
                name: PRODUCT_NAME,
                description: PRODUCT_DESC,
                colours: product.colours,
                // price: product.price,  // TODO may require string
                item_code: product.item_code,
            }),
        })
            .then(res => res.data.url)
            .then(url => newTab.location.href = url)
            .catch(err => console.error(err));
    }

    return (
        <>
        <Button
            variant=''
            className='cursorPointer TeemillButton-bounce buttonStyle-gold'
            onClick={handleCreate}>
            <div
                className="cursorPointer Home-title"
                style={{width: "auto", maxHeight: "8vw", fontSize: "calc(10px + 1vmin)"}}
                >{productsSVG}
                <br />
                Browse Products
            </div>
            { !isMobile &&
            <TShirt product={product}>
                <MaskedImage src={image} mask={mask} canvas={{ ref: canvasRef, type: product.canvas }} />
            </TShirt>}
        </Button>
        </>
    )
}

const PRODUCT_NAME = "MyBumbleTiger";
const PRODUCT_DESC = "Get This Design On Any Product. To open the product catalogue and editor, select any item from the product preview below. We offer a wide range of organic cotton jumpers, hoodies, t-shirts, tote bags and more! Create your own art prints, stickers and even custom puzzles.";

const PRODUCT_PREVIEW = {
    name: PRODUCT_NAME,
    description: PRODUCT_DESC,
    colours: "White,Black",
    // price: 20,  // TODO may require string
    item_code: "RNC1",
}